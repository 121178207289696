
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TicketSettingsAuthenticateTable from '@/components/modals/tickets/settings/inboxAuthenticate/TicketSettingsAuthenticateTable.vue'
import type { TableHeaders } from '@/definitions/shared/types'
import TmAlert from '@/components/shared/TmAlert.vue'

export default defineComponent({
  components: {
    TmAlert,
    TicketSettingsAuthenticateTable,
    TmModal,
    TmButton,
  },
  setup() {
    const counter = ref(0)
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Host', value: 'host-slot', hideSortable: true },
      { text: 'Type', value: 'type-slot', hideSortable: true },
      { text: 'Value', value: 'value-slot', hideSortable: true },
      { text: 'Status', value: 'status-slot', hideSortable: true },
    ])

    const tableItems = [
      {
        host: 'em7227.biggerinside.info',
        type: 'TXT',
        value: 'v=spf1 include u243522.wi321.@yahoo.com\nmail.textmagic.com ps4:1983.3,3 344.33\ninclude:helptextmagic.com - all',
        status: false,
      },
    ]

    return {
      tableItems,
      tableHeaders,
      counter,
    }
  },
})
