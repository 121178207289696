
import { defineComponent, ref } from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import { copyToClipboard } from '@/services/utils'

export default defineComponent({
  components: {
    TmTable,
    TmTooltip,
  },
  setup() {
    const copiedBuffer = ref('Copy')
    const copyFunc = (text: string) => {
      copiedBuffer.value = 'Copied'
      copyToClipboard(text)
    }
    const restoreCopy = () => setTimeout(() => {
      copiedBuffer.value = 'Copy'
    }, 200)

    return {
      copiedBuffer,
      copyFunc,
      restoreCopy,
    }
  },
})
